import $t from '@/setup/i18n';

export const transferType = {
  ach: 'ach',
  wire: 'wire',
  card: 'card',
  physicalCheck: 'physicalCheck',
  cash: 'cash',
  intrabank: 'intrabank',
  domesticWire: 'domesticWire',
  internationalWire: 'internationalWire',
  solidCard: 'solidCard',
  unknown: 'unknown',

  titles: {
    ach: $t.t('enum_TransferType_ACH'),
    wire: $t.t('enum_TransferType_wire'),
    card: $t.t('enum_TransferType_card'),
    physicalCheck: $t.t('enum_TransferType_check'),
    cash: $t.t('enum_TransferType_cash'),
    intrabank: $t.t('enum_TransferType_intrabank'),
    domesticWire: 'D-Wire',
    internationalWire: 'Int-Wire',
    solidCard: $t.t('enum_TransferType_solidcard'),
  }
};
